import React, { useState, useRef, useEffect, useCallback } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import ScrollOut from "scroll-out"
import widont from "widont"

import Contributors from "../../../components/athena/contributors.js"
import AthenaCta from "../../../components/athena/athenaCta.js"
import Layout, { athenaTheme } from "../../../components/layout"
import MoreArticles from "../../../components/athena/moreArticles.js"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import Image from "../../../components/image"
import VideoInline from "../../../components/videoInline"

import styles from "./face-filters.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  const athenaCta = article.athenaCta

  // Assets
  const portrait = data.craft.portrait
  const filterGrid = data.craft.filterGrid
  const filterArray = data.craft.filterArray
  const filterRefraction = data.craft.filterRefraction
  const filterFluid = data.craft.filterFluid
  const filterUndefined = data.craft.filterUndefined
  const galleryAssets = [
    {
      video: data.craft.galleryVideo1,
      thumbnail: data.craft.galleryThumbnail1,
    },
    {
      video: data.craft.galleryVideo2,
      thumbnail: data.craft.galleryThumbnail2,
    },
    {
      video: data.craft.galleryVideo3,
      thumbnail: data.craft.galleryThumbnail3,
    },
    {
      video: data.craft.galleryVideo4,
      thumbnail: data.craft.galleryThumbnail4,
    },
  ]

  // refs
  const galleryRef = useRef(null)
  const [activeGalleryIndex, setActiveGalleryIndex] = useState(0)
  const marqueeRef = useRef(null)
  const selectGalleryVideo = useCallback(
    index => {
      if (index === activeGalleryIndex) return
      galleryRef.current.querySelector("video").load()
      setActiveGalleryIndex(index)
    },
    [activeGalleryIndex]
  )

  // Initialize up ScrollOut library
  useEffect(() => {
    let so = ScrollOut({
      cssProps: {
        elementHeight: true,
        viewportY: true,
        visibleY: true,
        offsetY: true,
        intersectY: true,
        scrollPercentY: true,
      },
    })

    return so.teardown
  }, [])

  // Marquee
  useEffect(() => {
    if (marqueeRef.current == null) return
    let frameId = null
    let offs = 0
    let direction = 1
    function frame() {
      let h = marqueeRef.current
      if (h != null) {
        let width = h.children[0].getBoundingClientRect().width
        h.style.transform = `translateX(${
          direction === 1 ? -(offs % width) : -width - (offs % width)
        }px)`
      }
      offs += 2 * direction
      window.requestAnimationFrame(frame)
    }
    frame()

    return () => {
      window.cancelAnimationFrame(frameId)
    }
  }, [marqueeRef])

  return (
    <Layout seo={seo} mode="athena" theme={theme}>
      <Helmet>
        <body className={styles.body} />
      </Helmet>
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
          {/* Page needs to start with the title & subtitle for SEO and accessibility reasons */}
          <div className="screen-reader-only">
            <h1>{article.title}</h1>
            <div itemProp="subheading">
              <p>{article.subtitle}</p>
            </div>
          </div>
          <div className={styles.articleBody}>
            {/* STRETCH */}
            <div data-scroll="" className={styles.stretch} aria-hidden="true">
              <div className={styles.stretchDot}>
                <svg preserveAspectRatio="none" viewBox="0 0 200 200">
                  <ellipse cx="100" cy="100" rx="100" ry="100" fill="black" />
                </svg>
              </div>
              <div className={styles.stretchDot}>
                <svg preserveAspectRatio="none" viewBox="0 0 200 200">
                  <ellipse cx="100" cy="100" rx="100" ry="100" fill="black" />
                </svg>
              </div>
              <div className={styles.stretchDot}>
                <svg preserveAspectRatio="none" viewBox="0 0 200 200">
                  <ellipse cx="100" cy="100" rx="100" ry="100" fill="black" />
                </svg>
              </div>
              <div className={styles.stretchDot}>
                <svg preserveAspectRatio="none" viewBox="0 0 200 200">
                  <ellipse cx="100" cy="100" rx="100" ry="100" fill="black" />
                </svg>
              </div>
              <div className={styles.stretchDot}>
                <svg preserveAspectRatio="none" viewBox="0 0 200 200">
                  <ellipse cx="100" cy="100" rx="100" ry="100" fill="black" />
                </svg>
              </div>
              <div className={styles.stretchDot}>
                <svg preserveAspectRatio="none" viewBox="0 0 200 200">
                  <ellipse cx="100" cy="100" rx="100" ry="100" fill="black" />
                </svg>
              </div>
              <div className={styles.stretchDot}>
                <svg preserveAspectRatio="none" viewBox="0 0 200 200">
                  <ellipse cx="100" cy="100" rx="100" ry="100" fill="black" />
                </svg>
              </div>
              <div className={styles.stretchDot}>
                <svg preserveAspectRatio="none" viewBox="0 0 200 200">
                  <ellipse cx="100" cy="100" rx="100" ry="100" fill="black" />
                </svg>
              </div>
            </div>
            {/* STATEMENT */}
            <div className={styles.statement}>
              <h2 className={styles.statementText}>
                Identity today is a work in progress
              </h2>
            </div>
            {/* HALVES/MORPH */}
            <p className={styles.halves}>
              <span>
                <span data-scroll="" className={styles.morph}>
                  <span className={styles.morphText}>
                    {widont(`We have more ways than ever to broadcast our always-evolving
                  selves,`)}
                  </span>
                </span>
              </span>
              <span>
                <span className={styles.p}>
                  {widont(`but brands aren’t keeping pace.`)}
                </span>
              </span>
            </p>
            <p>
              {/* HEADLINES */}
              <span className={styles.headlines}>
                <span className={styles.headlinesLeft}>
                  Rigidity {widont(`& Control`)}{" "}
                </span>
                <span className={styles.headlinesRight}>
                  are still mostly the standard.{" "}
                </span>
              </span>
              {/* MORPH LENS */}
              <span data-scroll="" className={styles.morphLens}>
                <span className={styles.morphLensText}>
                  Instead, we see a need for participatory creative that
                  encourages fluid expression...
                </span>
              </span>
            </p>
            {/* LENS */}
            <h2 className={`${styles.lens} ${styles.lensLight}`}>
              <span className={styles.lensText}>So what did we do?</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 200 200"
                className={styles.lensBg}
                role="presentation"
              >
                <circle cx="100" cy="100" r="100" fill="black" />
              </svg>
            </h2>
            <p>
              {/* STATEMENT SMALL */}
              <span className={styles.statementSmall}>We looked at </span>
              {/* SQUISH/HALVES */}
              <span className={styles.halves}>
                <span className={styles.squishLeft}>
                  <span className={styles.squishItem}>
                    <span className={styles.squishItemText} data-scroll="">
                      AR{" "}
                    </span>
                  </span>
                  <span className={styles.squishFooter}>
                    (augmented reality){" "}
                  </span>
                </span>
                <span>
                  <span
                    data-scroll=""
                    className={`${styles.morph} ${styles.morphInverse}`}
                  >
                    <span
                      className={`${styles.morphText} ${styles.morphTextBig}`}
                    >
                      as a place where technology and people are merging in the
                      service of identity-building.
                    </span>
                  </span>
                </span>
              </span>
            </p>
            <p>
              {/* SUBHEAD */}
              <span className={styles.subhead}>So, we partnered with </span>
              {/* PROFILE/PEEK/HALVES */}
              <span className={styles.halves}>
                <span data-scroll="" className={styles.profile}>
                  <span>rising nonbinary musician </span>
                  <span>
                    <span className={styles.peek}>
                      <span className={styles.peekText}>
                        Dua
                        <br />
                        Saleh{" "}
                      </span>
                    </span>
                  </span>
                  <span>to experiment with depictions of identity. </span>
                </span>
                <span>
                  <Image
                    width={portrait.width}
                    height={portrait.height}
                    url={portrait.url}
                    alt={portrait.altText}
                  />
                </span>
              </span>
            </p>
            <p>
              {/* MINISTRETCH */}
              <span className={styles.ministretch} data-scroll="">
                <span className={styles.ministretchDots} aria-hidden="true">
                  <span className={styles.ministretchDot}>
                    <svg preserveAspectRatio="none" viewBox="0 0 200 200">
                      <circle cx="100" cy="100" r="100" fill="black" />
                    </svg>
                  </span>
                  <span className={styles.ministretchDot}>
                    <svg preserveAspectRatio="none" viewBox="0 0 200 200">
                      <circle cx="100" cy="100" r="100" fill="black" />
                    </svg>
                  </span>
                  <span className={styles.ministretchDot}>
                    <svg preserveAspectRatio="none" viewBox="0 0 200 200">
                      <circle cx="100" cy="100" r="100" fill="black" />
                    </svg>
                  </span>
                  <span className={styles.ministretchDot}>
                    <svg preserveAspectRatio="none" viewBox="0 0 200 200">
                      <circle cx="100" cy="100" r="100" fill="black" />
                    </svg>
                  </span>
                </span>
                <span className={styles.ministretchText}>
                  <span className={styles.ministretchText1}>
                    And we worked with{" "}
                  </span>
                  <span className={styles.ministretchText2}>
                    {widont(`four AR artists who could help us`)}
                  </span>
                </span>
              </span>
              {/* HEADLINE */}
              <span className={styles.headline}>
                make the project participatory.{" "}
              </span>
            </p>
            {/* LENS */}
            <h2 className={styles.lens}>
              <span className={styles.lensText}>So, what is the project?</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 200 200"
                className={styles.lensBg}
                role="presentation"
              >
                <circle cx="100" cy="100" r="100" fill="black" />
              </svg>
            </h2>
            {/* PARAGRAPH */}
            <p className={styles.paragraph}>
              <span className={styles.paragraphText}>
                {widont(`We created the first-ever fan-contributed music video made
                entirely of custom Instagram face filters for a new song by the
                rising recording artist, Dua Saleh—an openly queer, nonbinary
                artist who embodies fluidity in all aspects of life.`)}
              </span>
            </p>
            {/* MORPH/HALVES */}
            <p className={styles.halves}>
              <span>
                <span
                  data-scroll=""
                  className={`${styles.morph} ${styles.morphInverse}`}
                >
                  <span
                    className={`${styles.morphText} ${styles.morphTextBig}`}
                  >
                    {widont(`Our filter designers were selected for their individual styles
                and technical capabilities.`)}
                  </span>
                </span>
              </span>
              <span>
                <span data-scroll="" className={styles.morph}>
                  <span
                    className={`${styles.morphText} ${styles.morphTextBig}`}
                  >
                    {widont(
                      `We collaborated on design, functionality and launch.`
                    )}
                  </span>
                </span>
              </span>
            </p>
            {/* LIST/HALVES */}
            <div className={styles.halves}>
              <div className={styles.listCopy}>
                <div className={styles.listCopyText}>
                  <p>
                    The filters use the language of the selfie with a really
                    high-tech output.
                  </p>
                  <p>
                    Our team landed on four distinct filters that represented
                    our principles of fluidity:
                  </p>
                </div>
              </div>
              <div className={styles.listList}>
                <ul>
                  <li>Refraction</li>
                  <li>Array</li>
                  <li>Fluid</li>
                  <li>Undefined</li>
                </ul>
              </div>
            </div>
            {/* FLIP */}
            <div className={styles.flip}>
              <figure className={styles.flipItem}>
                <div className={styles.flipItemVideo} data-scroll="">
                  <div className={styles.flipItemVideoBack}></div>
                  <div className={styles.flipItemVideoFront}>
                    <VideoInline
                      src={filterRefraction.url}
                      type={filterRefraction.mimeType}
                    />
                  </div>
                </div>
                <figcaption>
                  <span className={styles.flipItemTitle}>Refraction</span>
                  <a
                    className={styles.flipItemCreator}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/frenetikvoid/"
                  >
                    @<span>frenetikvoid</span>
                  </a>
                </figcaption>
              </figure>
              <figure className={styles.flipItem}>
                <div className={styles.flipItemVideo} data-scroll="">
                  <div className={styles.flipItemVideoBack}></div>
                  <div className={styles.flipItemVideoFront}>
                    <VideoInline
                      src={filterArray.url}
                      type={filterArray.mimeType}
                    />
                  </div>
                </div>
                <figcaption>
                  <span className={styles.flipItemTitle}>Array</span>
                  <a
                    className={styles.flipItemCreator}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/autonommy/"
                  >
                    @<span>autonommy</span>
                  </a>
                </figcaption>
              </figure>
              <figure className={styles.flipItem}>
                <div className={styles.flipItemVideo} data-scroll="">
                  <div className={styles.flipItemVideoBack}></div>
                  <div className={styles.flipItemVideoFront}>
                    <VideoInline
                      src={filterFluid.url}
                      type={filterFluid.mimeType}
                    />
                  </div>
                </div>
                <figcaption>
                  <span className={styles.flipItemTitle}>Fluid</span>
                  <a
                    className={styles.flipItemCreator}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/exitsimulation/"
                  >
                    @<span>exitsimulation</span>
                  </a>
                </figcaption>
              </figure>
              <figure className={styles.flipItem}>
                <div className={styles.flipItemVideo} data-scroll="">
                  <div className={styles.flipItemVideoBack}></div>
                  <div className={styles.flipItemVideoFront}>
                    <VideoInline
                      src={filterUndefined.url}
                      type={filterUndefined.mimeType}
                    />
                  </div>
                </div>
                <figcaption>
                  <span className={styles.flipItemTitle}>Undefined</span>
                  <a
                    className={styles.flipItemCreator}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/after_form/"
                  >
                    @<span>after_form</span>
                  </a>
                </figcaption>
              </figure>
            </div>
            {/* MARQUEE */}
            <h3 className={styles.marquee}>
              <span className={styles.marqueeWrapper} ref={marqueeRef}>
                <span className={styles.marqueeText}>Launch via open call</span>
                <span className={styles.marqueeText} aria-hidden={true}>
                  Launch via open call
                </span>
                <span className={styles.marqueeText} aria-hidden={true}>
                  Launch via open call
                </span>
              </span>
            </h3>
            {/* LENS */}
            <p className={styles.lens}>
              <span className={`${styles.lensText} ${styles.lensTextSmall}`}>
                {widont(`‍Friday, Dec 6, 2019: “Pretty Kitten” launch via an open call on
                Dua Saleh’s Instagram profile.`)}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 200 200"
                className={styles.lensBg}
                role="presentation"
              >
                <circle cx="100" cy="100" r="100" fill="black" />
              </svg>
            </p>
            {/* GALLERY */}
            <div ref={galleryRef} className={styles.gallery}>
              <div>
                <p className={styles.galleryCaption}>
                  {widont(`Fans were directed to play the songs, capture themselves
                  lip-syncing, posing or dancing and submit clips via DM for a
                  chance to be featured in the official music video.`)}
                </p>
                <div className={styles.galleryThumbs}>
                  {galleryAssets.map((item, index) => {
                    return (
                      <button
                        onClick={() => selectGalleryVideo(index)}
                        onMouseOver={() => selectGalleryVideo(index)}
                        onFocus={() => selectGalleryVideo(index)}
                        key={`ff-gallery-${index}`}
                        className={`${styles.galleryThumb} ${
                          activeGalleryIndex === index
                            ? styles.thumbIsActive
                            : ""
                        }`}
                      >
                        <Image
                          crop={true}
                          aspectRatio="255:217"
                          sizes="(min-width: 768px) 25vw, 50vw"
                          width={item.thumbnail.width}
                          height={item.thumbnail.height}
                          url={item.thumbnail.url}
                          alt={item.thumbnail.altText}
                          focalPoint={item.thumbnail.focalPoint}
                        />
                      </button>
                    )
                  })}
                </div>
              </div>
              <div className={styles.galleryVideo}>
                <VideoInline
                  src={galleryAssets[activeGalleryIndex].video.url}
                  type={galleryAssets[activeGalleryIndex].video.mimeType}
                />
              </div>
            </div>
            {/* LENS */}
            <h3 className={styles.lens}>
              <span className={styles.lensText}>The Launch Party</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 200 200"
                className={styles.lensBg}
                role="presentation"
              >
                <circle cx="100" cy="100" r="100" fill="black" />
              </svg>
            </h3>
            {/* PEEKAGRAPH */}
            <p data-scroll="" className={styles.peekagraph}>
              <span className={styles.peekagraphLeft}>
                <span className={styles.peekagraphPeek}>
                  <span className={styles.peekagraphPeekText}>
                    {widont(
                      `We also curated a secret invite-only launch event.`
                    )}{" "}
                  </span>
                </span>
              </span>
              <span className={styles.peekagraphRight}>
                Fans were able to connect with Dua Saleh, learn how to play with
                the filters and interact with them in vibrant and engaging
                spaces.
              </span>
            </p>
            {/* LENS */}
            <h2
              data-scroll=""
              className={`${styles.lens} ${styles.lensLight} ${styles.lensRotate}`}
            >
              <span className={styles.lensText}>The Result</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 200 200"
                className={styles.lensBg}
                role="presentation"
              >
                <circle cx="100" cy="100" r="100" fill="black" />
              </svg>
            </h2>
            {/* HEADLINE */}
            <p className={`${styles.headline} ${styles.headlineCenter}`}>
              After launch, fans had four days to submit unlimited clips.
            </p>
            {/* VIDEOLENS */}
            <div className={styles.videoLens} data-scroll="">
              <div className={styles.videoLensVideo}>
                <VideoInline src={filterGrid.url} type={filterGrid.mimeType} />
              </div>
            </div>
            {/* STRIPES/HALVES */}
            <p className={styles.halves}>
              <span>
                <span className={styles.stripesBody}>
                  {widont(`We incorporated the best submissions into the final official
                  music video,`)}{" "}
                </span>
              </span>
              <span>
                <span className={styles.stripesStripe}>
                  which premiered on{" "}
                </span>
                <span
                  className={`${styles.stripesStripe} ${styles.stripesStripeDark}`}
                >
                  The
                  <br />
                  FADER{" "}
                </span>
                <span className={styles.stripesStripe}>on Dec 13, 2019.</span>
              </span>
            </p>
            {/* DOTS */}
            <div className={styles.dotsParent}>
              <div className={styles.dots} data-scroll=""></div>
            </div>
            {/* Video */}
            <div className={styles.video}>
              <div className={styles.videoEmbed}>
                <div className={styles.videoEmbedContainer}>
                  <iframe
                    title="face-filters"
                    src="https://player.vimeo.com/video/389531731"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              </div>
            </div>
            {/* HEADLINE/CURTAIN */}
            <div className={styles.curtain}>
              <p className={styles.curtainInner}>
                <span
                  className={`${styles.headline} ${styles.headlineSmaller}`}
                >
                  The result is participatory brand storytelling that evolves
                  with (and for) every user.
                </span>
              </p>
            </div>
          </div>
          {/* .article-body */}
          <Contributors
            zeus={article.zeusContributors}
            other={article.otherContributors}
          />
          <AthenaCta data={athenaCta} />
          <MoreArticles id={article.id} issue={article.issue[0].id} />
        </div>
        {/* .article */}
      </article>
      <PreFooter />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
      portrait: asset(id: 4648) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      filterGrid: asset(id: 4649) {
        url
        ... on Craft_athenaContent_Asset {
          url
          extension
          kind
          mimeType
        }
      }
      filterArray: asset(id: 4650) {
        url
        ... on Craft_athenaContent_Asset {
          url
          extension
          kind
          mimeType
        }
      }
      filterRefraction: asset(id: 4652) {
        url
        ... on Craft_athenaContent_Asset {
          url
          extension
          kind
          mimeType
        }
      }
      filterFluid: asset(id: 4651) {
        url
        ... on Craft_athenaContent_Asset {
          url
          extension
          kind
          mimeType
        }
      }
      filterUndefined: asset(id: 4653) {
        url
        ... on Craft_athenaContent_Asset {
          url
          extension
          kind
          mimeType
        }
      }
      galleryVideo1: asset(id: 4654) {
        url
        ... on Craft_athenaContent_Asset {
          url
          extension
          kind
          mimeType
        }
      }
      galleryThumbnail1: asset(id: 4658) {
        url
        ... on Craft_athenaContent_Asset {
          url
          width
          height
          focalPoint
          ... on Craft_athenaContent_Asset {
            altText
          }
        }
      }
      galleryVideo2: asset(id: 4655) {
        url
        ... on Craft_athenaContent_Asset {
          url
          extension
          kind
          mimeType
        }
      }
      galleryThumbnail2: asset(id: 4659) {
        url
        ... on Craft_athenaContent_Asset {
          url
          width
          height
          focalPoint
          ... on Craft_athenaContent_Asset {
            altText
          }
        }
      }
      galleryVideo3: asset(id: 4656) {
        url
        ... on Craft_athenaContent_Asset {
          url
          extension
          kind
          mimeType
        }
      }
      galleryThumbnail3: asset(id: 4660) {
        url
        ... on Craft_athenaContent_Asset {
          url
          width
          height
          focalPoint
          ... on Craft_athenaContent_Asset {
            altText
          }
        }
      }
      galleryVideo4: asset(id: 4657) {
        url
        ... on Craft_athenaContent_Asset {
          url
          extension
          kind
          mimeType
        }
      }
      galleryThumbnail4: asset(id: 4661) {
        url
        ... on Craft_athenaContent_Asset {
          url
          width
          height
          focalPoint
          ... on Craft_athenaContent_Asset {
            altText
          }
        }
      }
    }
  }
`
